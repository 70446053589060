.order-confirm {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #fff;

        &.big {
            font-size: 24px;
            line-height: 36px;
        }
    }

    .ok-icon {
        width: 80px;
        height: 80px;
        margin: auto;
    }

}