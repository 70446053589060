.select-card {
    cursor: pointer;
    border: 1px solid #502F88;

    .card-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: default;
        padding-left: 20px;
        padding-right: 20px;

        &:hover {
            color: #797C81;
        }

        .left-box,
        .right-box {
            width: 30%;
            text-align: left;
            display: flex;
            flex-direction: column;
            max-width: max-content;

            .fdetails {
                color: "#797C81";
                font-size: 10px;
                text-align: left;

            }

            .per-month {
                font-size: 12px;
                color: #000;
            }

            .amount {
                font-size: 14px;
                font-weight: 500;
                color: #000;
                text-align: left;
            }
        }
    }

    .ant-card-head {
        border-bottom: 0px;
    }

    .ant-card-body {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .desc {
        color: #797C81;
    }

    .view-link {
        color: #502F88;
        cursor: pointer;
    }
}

.detail-bullet {
    display: flex;
    align-items: center;

    .point {
        margin-left: 5px;
    }

    img {
        width: 20px;
        height: 20px;
    }

}