.insurance-embed-box {
    padding: 24px;

    &.green {
        background-color: #EAFEE9;
    }

    .head {
        gap: 20px;

        .heading {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            .small-heading {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
            }
        }

        .embed-subtitle {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;

            span {
                font-weight: 600;
            }
        }

        .powered-by {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #797C81;
            font-family: Inter;
            font-style: italic;
        }

        .bprimary {

            &:hover {
                background-color: #502F8820;
            }

            &.active {
                color: #7E1313;
                border-color: #7E1313;

                &:hover {
                    background-color: #7E131320;
                }
            }
        }

    }

    .body {
        gap: 20px;
        margin-top: 24px;

        .heading {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
        }

        .list {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;

            li {
                margin-bottom: 4px;
            }
        }

        .bullet-points {
            margin-left: -20px;

            .read-more {
                cursor: pointer;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                margin-top: 8px;
                color: #502F88
            }
        }
    }
}