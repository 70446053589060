.nav {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 420px;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  border-top: 1px solid #dcdcdc;
  z-index: 1000;

  .nav-wrapper {
    .nav-item {
      height: 100%;
    }
  }

  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    cursor: pointer;

    .nav-icon {
      font-size: 24px;
      color: #444444;
    }

    .nav-label {
      font-size: 12px;
      color: #444444;
      margin-top: 4px;
    }

    &.active {
      border-top: 2px solid #faad14;
      margin: 0 24px;

      .nav-icon,
      .nav-label {
        color: #faad14;
      }
    }
  }

  @media (min-width: 768px) {
    max-width: 420px;
  }
}