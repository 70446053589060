.ant-steps-item-icon {
  display: none !important;
}

.ant-steps-item-title::after {
  background-color: #f5f5f5 !important;
  content: '';
  display: block;
  height: 1px;
  border-bottom: 1px dashed #C9CACD;
  margin: 0 8px;
}

.ant-steps-item-active {
  .ant-steps-item-title {
    color: #502F88 !important;
    text-decoration: underline;
  }
}

.payment-success {
  background-color: #f5f5f5;

  .order-confirm {
    text-align: center;
    padding: 20px;
    background-color: #30A363;
    color: #fff;

    .ok-icon {
      width: 60px;
      height: 60px;
      margin-bottom: 8px;
    }

    .heading {
      font-size: 24px;
      font-weight: bold;
    }

    .booking-id {
      font-size: 16px;
      margin-bottom: 20px;
      color: #fff;
    }

    .download-button {
      background-color: #ffffff;
      color: #2A8954;
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 16px;
      border: none !important;
    }

  }


}

.policy-page {
  background-color: #fff;
  padding: 24px;
}

.home {

  .cart-icon {
    font-size: 24px;
  }

  .search {
    padding: 24px;

    .search-bar {
      background: #FBFBFB;
      height: 40px;

      border: 1px solid #F1F1F1 svg {
        fill: #797C81;
        color: #797C81;
      }
    }
  }

  .content {
    padding: 24px;
    padding-top: 0px;
  }

  .banner {
    position: relative;
    margin-bottom: 16px;

    img {
      width: 100%;
    }

  }

  .categories {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .action-tile-box{
      padding: 0px !important;
    }

    .icon.center {
      max-width: 58px;
      max-height: 58px;
      min-width: 58px;
      min-height: 58px;
      padding: 0px !important;
      font-size: 18px;
      align-items: center;
      box-shadow: none !important;
      border: none !important;
      cursor: unset !important;
    }

    img {
      cursor: pointer;
    }
  }

  .brands {
    display: flex;
    gap: 16px;
    margin-bottom: 48px;

    .title {
      height: 21px;
      justify-content: center;
      display: flex;
      align-items: center;
      margin-top: 8px;

      .brand-name {
        color: #383E42;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
      }
    }

    .brand-card {
      width: 167px;
      height: 169px;

      .ant-card-cover {

        img {
          width: 167px;
          height: 169px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}

.listing {
  background-color: #f5f5f5;

  .product-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;

    .product-list-box {
      background-color: #fff;
      padding: 24px;
      overflow: hidden;
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
}

.product-detail {

  .product-image {
    text-align: center;
    margin-bottom: 24px;

    img {
      width: 100%;
      max-width: 300px;
    }

    .image-dots {
      margin-top: 16px;

      .dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #F5F5F5;
        margin: 0 4px;
        cursor: pointer;

        &.active {
          background-color: #383E42;
        }
      }
    }
  }

  .product-info {
    margin-bottom: 24px;
    padding: 24px;

    h4 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0px !important;
    }

    .brand {
      font-size: 14px;
    }

    .price-info {
      margin-top: 8px;

      .current-price {
        font-size: 20px;
        font-weight: 600;
        margin-right: 8px;
      }

      .discount {
        color: #11A387;
        font-size: 19.5px;
        font-weight: 500;
      }
    }

    .mrp {
      display: block;
      margin-top: 4px;
      font-size: 16px;
      color: #797C81;

      .strike-through {
        text-decoration: line-through;
        color: #797C81;
      }
    }
  }

  .quantity-selector {
    padding: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    gap: 16px;

    .label {
      font-weight: 500;
      color: #383E42;
      font-size: 16px;
    }

    .ant-select-selector {
      padding: 8px 16px 8px 16px;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid #828282;

      .ant-select-selection-item {
        color: #828282;
      }
    }
  }

  .insurance {
    border-top: 8px solid #f5f5f5;
    border-bottom: 8px solid #f5f5f5;
  }

  .summary {
    padding: 24px;
  }

  .total-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .total {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .saved {
      display: flex;
      align-items: center;
      gap: 4px;

      .amt {
        font-weight: 600;
      }
    }
  }

  .delivery-info {
    display: block;
    margin-bottom: 32px;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;

    .add-to-cart,
    .buy-now {
      flex: 1;
      width: 100%;
      height: 40px;
      font-weight: 500;
      font-size: 16px;
    }

    .add-to-cart {
      margin-right: 8px;
    }
  }
}

.book-flight-form {
  padding: 16px;
  background-color: #fff;

  img {
    margin-right: 10px;
  }

  .trip-type {
    display: flex;
    margin-bottom: 24px;

    .ant-radio-checked .ant-radio-inner {
      border-color: #502F88 !important;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background-color: #502F88;
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: #502F88;
    }

    .ant-radio-inner {
      background-color: #fff;
    }
  }

  .form-group {
    margin-bottom: 16px;
    position: relative;

    label {
      position: absolute;
      left: 12px;
      transform: translateY(-50%);
      font-size: 14px;
      color: #797C81;
      font-weight: 400;
      transition: all 0.3s ease;
      pointer-events: none;
      background-color: white;
      padding: 0 4px;
    }

    &.focused label,
    .input-group:not(:placeholder-shown)+label {
      top: 0;
      font-size: 12px;
      color: #502F88;
    }

    .input-group {
      display: flex;
      align-items: center;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      padding: 10px 16px;
      cursor: text;

      &:focus-within {
        border-color: #502F88;
      }

      svg {
        margin-right: 12px;
      }

      strong {
        font-size: 16px;
        margin-right: 4px;
      }

      small {
        display: block;
        font-size: 12px;
        color: #797C81;
      }
    }
  }

  .form-row {
    display: flex;
    gap: 16px;

    .half-width {
      flex: 1;
    }
  }

  .add-return {
    color: #502F88;
    font-weight: 500;
    font-size: 14px;
  }

  .primary {
    width: 100%;
    background-color: #502F88;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 24px;
    position: absolute;
    bottom: 16px;
    max-width: 388px;
    width: calc(100% - 32px);
  }
}

.cart-page {

  .cart-steps {
    margin-bottom: 24px;
    padding: 24px;
  }

  .cart-items {
    margin-bottom: 16px;
    padding: 0 24px;

    .product-actions {
      display: flex;
      align-items: center;
      margin-top: 8px;
    }

    .delete-icon {
      color: #ff4d4f;
      font-size: 18px;
      cursor: pointer;
    }
  }


  .cart-divider {
    padding: 0 24px;

    .divider {
      color: #C9CACD;
      background-color: #C9CACD;
    }

  }

  .add-products-button {
    display: block;
    margin: 16px 24px;
    width: 170px;
    height: 37px;
    font-size: 16px;
    font-weight: 500;
  }

  .separator {
    border-top: 8px solid #f5f5f5;
    margin-bottom: 0px;
  }

  .price-details {
    margin: 24px;
    margin-top: 0px;
    border-radius: 8px;

    h5 {
      margin-top: 0px !important;
      padding: 16px 0;
      border-bottom: 1px solid #f5f5f5;
      font-size: 20px;
    }

    .price-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      span {
        font-size: 16px;
      }

      &.total {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid #d9d9d9;
      }
    }

    // .discount {
    //   color: #11A387;
    // }
  }

  .terms-submit {
    padding: 24px;

    .redirect-info,
    .terms-info {
      display: block;
      margin-top: 16px;
      font-size: 16px;
      color: #383E42;

      a {
        color: #3984DA;
        font-size: 16px;
      }
    }

    .proceed-button {
      margin-top: 24px;
      width: 100%;
      height: 37px;
      font-size: 16px;
      font-weight: 500px;
    }
  }

}

.checkout {

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .time {
      font-weight: bold;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .flight-info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .airline-logo {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    .flight-details {
      width: 100%;

      img {
        width: 100%;
      }

      .route {
        font-size: 18px;
        font-weight: bold;
        display: block;
      }

      .schedule {
        font-size: 14px;
        color: #666;
      }
    }
  }

  .passenger-info {
    background-color: #fff;
    margin-bottom: 8px;

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      padding: 16px 24px;

      svg {
        fill: #502F88;
        width: 20px;
        height: 20px;
      }

      .section-title {
        font-size: 16px;
        font-weight: bold;
      }

      .edit-icon {
        color: #0856DF;
      }
    }

    .passenger {
      padding: 12px 24px;
    }

    .passenger-email,
    .passenger-phone {
      display: block;
      font-size: 14px;
      color: #797C81;
    }

    .passenger-email {
      margin-bottom: 16px;
    }

    .passenger-name {
      font-weight: 500;
      font-size: 16px;
      color: #383E42;
      margin-bottom: 2px;
    }
  }

  .insurance-embed-box {
    margin-bottom: 125px;
    background-color: #fff !important;
  }

  .coverage-info {
    margin-bottom: 24px;

    .coverage-title {
      font-weight: bold;
      display: block;
      margin-bottom: 8px;
    }

    ul {
      padding-left: 20px;

      li {
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
  }

  .total-section {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #ffffff;
    border-top: 1px solid #e8e8e8;
    position: absolute;
    bottom: 0px;
    width: calc(100% - 32px);
    box-shadow: 0px -1px 4px 0px #00000040;


    .total {
      display: flex;
      flex-direction: column;

      .total-price {
        font-weight: 600;
        font-size: 22px;
        color: #383E42;
      }

      .total-title {
        font-size: 14px;
        font-weight: 500;
        color: #797C81;
      }
    }
  }

  .proceed-button {
    background-color: #502F88;
    color: #ffffff;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    height: 40px;
    padding: 8px 24px 8px 24px;
    gap: 10px;
    border-radius: 4px;
    border: none;

    &:hover {
      opacity: 0.9;
    }
  }
}


.orders-page {
  .flight-details {
    margin-top: 16px;
  }
}