* {
  color: #383E42;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.pointer {
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.ant-menu-item-selected{
  background-color: #502F8833 !important;
}

.primary {
  padding: 8px 24px 8px 24px;
  gap: 10px;
  border-radius: 4px;
  background: #502F88;
  color: #000;
  border: 1px solid #502F88;
  cursor: pointer;

  &:hover{
    opacity: 0.9;
  }

}

.outline {
  padding: 8px 24px 8px 24px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #502F88;
  background: #FFF;
  color: #502F88;
  cursor: pointer;

  &:hover{
    background: #502F8820;
    color: #000;
  }
}

.layout {
  min-height: 100vh;
  background-color: #fff;
  max-width: 420px;
  margin: auto;
  overflow: hidden;
  position: relative;

  @media (max-width: 420px) {
    &.bg-gray {
      background-color: #f5f5f5;
    }
  }

  &.bg-gray-all {
    background-color: #f5f5f5;
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.m-auto {
  margin: auto;
}

.space {
  height: 20px;
}


body {
  background-color: #f5f5f5;
  margin: 0px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}