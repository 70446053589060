.flight-details {
    margin-top: 8px;
    background-color: #fff;

    .flight-details-title {
        font-size: 18px;
        font-weight: 600;
        padding: 16px 24px;
        border-bottom: 1px solid #f5f5f5;
    }

    .flight-details-left {
        text-align: left;
    }

    .flight-info {
        display: flex;
        align-items: center;
        padding: 16px 24px;

        .airline-logo {
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }

        .route {
            font-weight: bold;
            font-size: 16px;
        }

        .flight-time {
            font-size: 14px;
            color: #666;
        }
    }


    .btn-area {
        padding: 16px 24px;
        padding-top: 0px;

        .details-button {
            color: #7b4fc3;
            width: 100%;
        }
    }

}


.checkout-info-header {
    background-color: #fff;

    .flight-info-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
    }

    .back-arrow {
        font-size: 18px;
        margin-right: 16px;
    }

    .route {
        display: flex;
        align-items: center;
        margin-right: 36px;

        .route-arrow {
            font-size: 12px;
            margin: 0 16px;
            color: #bfbfbf;
        }
    }

    .details {
        font-size: 12px;
    }
}