@keyframes toUpAnimate {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

.no-item-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  animation: toUpAnimate 0.5s ease-out;

  .no-item-img {
    max-width: 200px;
    margin-bottom: 1rem;
    animation: scaleIn 0.5s ease-out 0.2s both;
  }

  .no-item-text {
    font-size: 1.3rem;
    color: #8c8c8c;
  }

  .text-white {
    color: #fff;
  }

  .btn {
    margin-top: 1rem;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }
  }
}