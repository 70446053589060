.b-button {
    border-radius: 4px;
    padding: 8px 24px 8px 24px;
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    line-height: 16px;
    font-weight: 700;
    font-size: 12px;
    background-color: #fff;

    &.w-100 {
        width: 100px;
    }

    &.w-max {
        width: max-content;
    }

    &.bprimary {
        color: #502F88;
        border: 1px solid #502F88;

        &.filled {
            background-color: #502F88;
            color: white;
        }
    }

    &.bdanger {
        color: #7E1313;
        border: 1px solid #7E1313;

        &.filled {
            background-color: #7E1313;
            color: white;
        }
    }

    &.bwarning {
        color: #502F88;
        border: 1px solid #502F88;

        &.filled {
            background-color: #502F88;
            color: white;
        }
    }

    &.disabled {
        background-color: #C9CACD;
        border: 1px solid #C9CACD;
        color: #fff;
        cursor: not-allowed;
    }


}